
/**
 * @author  XuHongli
 * @date  2022/12/29 15:09
 * @version 1.0
 * @description
 */

const AdvHomePart7_Data = {
  one: [
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/one_1.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/one_2.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/one_3.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/one_4.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/one_5.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/one_6.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/one_7.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/one_8.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/one_9.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/one_10.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/one_11.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/one_12.png',
  ],
  two: [
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/two_1.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/two_2.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/two_3.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/two_4.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/two_5.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/two_6.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/two_7.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/two_8.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/two_9.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/two_10.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/two_11.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/two_12.png',
  ],
  three: [
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/three_1.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/three_2.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/three_3.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/three_4.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/three_5.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/three_6.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/three_7.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/three_8.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/three_9.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/three_10.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/three_11.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/three_12.png',
  ],
  four: [
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/four_1.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/four_2.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/four_3.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/four_4.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/four_5.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/four_6.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/four_7.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/four_8.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/four_9.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/four_10.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/four_11.png',
    process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/four_12.png',
  ]
}


export default AdvHomePart7_Data
