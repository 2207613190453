var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"AdvHomePart4"},[_c('div',{staticClass:"scene_block wow animate__zoomIn",attrs:{"data-wow-duration":"1s"}},[_vm._v(" 投放策略 ")]),_c('div',{staticClass:"scene_nav_isc"},_vm._l((_vm.AdvHomePart4_Data),function(item,index){return _c('div',{key:index,class:[
        'scene_nav_threebox',
        'wow',
        { animate__fadeInDown: index % 2 === 0 },
        { animate__fadeInUp: index % 2 === 1 },
      ],attrs:{"data-wow-duration":"1s"}},[_c('div',{staticClass:"scene_nav_threebox_top"},[_c('div',{staticClass:"scene_nav_threebox_img"},[_c('img',{attrs:{"src":item.imgUrl,"alt":""}})])]),_c('div',{staticClass:"scene_nav_threebox_bottom"},[_c('div',{staticClass:"scene_nav_threebox_bottom_title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"scene_nav_threebox_bottom_p"},[_vm._v(" "+_vm._s(item.text)+" ")]),_vm._m(0,true)])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scene_nav_threebox_bottom_ty"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v(" 立即体验 >")])])
}]

export { render, staticRenderFns }