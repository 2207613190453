<template>
  <div class="AdvHomePart5">
    <div class="block_maintitle" style="text-align: center">创新投放</div>
    <div class="product_content">
      <div
        class="product_li wow animate__zoomIn"
        data-wow-duration="1s"
        v-for="(item, index) in AdvHomePart5_Data"
        :key="index">
        <div class="product_cont_nav">
          <div class="product_cont">
            <div class="product_headline">
              <img :src="item.icon" alt="">
              <div>
                {{ item.title }}
              </div>
            </div>
            <div class="product_right">
              <img :src="item.imgUrl" alt="">
              <div>{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from 'wowjs'
import AdvHomePart5_Data from '@/views/ProductServer/AdvHome/Data/AdvHomePart5_Data'

/**
 * @author  XuHongli
 * @date  2022/12/29 9:34
 * @version 1.0
 * @description
 */
export default {
  name: 'AdvHomePart5',
  data() {
    return {
      AdvHomePart5_Data
    }
  },
  mounted() {
    new WOW.WOW().init()
  },
}
</script>

<style scoped lang="scss">
.AdvHomePart5{
  width: 100%;
  position: relative;
  padding: 70px 0 80px;
}
.product_content{
  width: 1280px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px auto auto;
  .product_li{
    width: 624px;
    border-radius: 10px;
    box-shadow: 0 3px 29px 1px rgba(73,75,78,.16);
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }
  .product_cont_nav{
    transition: all .8s;
    width: 100%;
    .product_cont{
      transition: all .8s;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 10px;
      display: flex;
      .product_headline{
        width: 206px;
        height: 245px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all .8s;
        img{
          transition: all .8s;
          width: 50px;
          height: 50px;
        }
        div{
          position: relative;
          font-size: 24px;
          font-family: Microsoft YaHei-Regular,Microsoft YaHei,serif;
          font-weight: 400;
          color: #222;
          line-height: 33px;
          margin-top: 15px;
          &::before{
            position: absolute;
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            width: 40px;
            height: 3px;
            background: red;
          }
        }
      }
    }
  }
  .product_right{
    position: relative;
    flex: 1;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    &::before{
      position: absolute;
      left: 0;
      content: "";
      width: 60px;
      height: 100%;
      background-color: #fff;
      clip-path: polygon(0 0,0 100%,100% 100%);
      z-index: 9;
    }
    img{
      width: 100%;
      height: 100%;
      transition: all .5s;
      &:hover{
        transform: scale(1.2);
      }
    }
  }
}
</style>
