/**
 * @author  XuHongli
 * @date  2022/12/28 18:37
 * @version 1.0
 * @description
 */
const AdvHomePart4_Data = [
  {
    title: '渠道匹配 资源选取',
    text: '对应不同的渠道和受众，策划有针对性的内容发布。',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngk_tf_img_001.png'
  },
  {
    title: '创意策划 制作发布',
    text: '根据推广策略规则，确定资源的渠道组合，推广周期和发布时间。',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngk_tf_img_002.png'
  },
  {
    title: '效果评估 参考指引',
    text: '投放数据分析与效果评估，为下一次的投放做参考和指引。',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngk_tf_img_003.png'
  }
]

export default AdvHomePart4_Data
