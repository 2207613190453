<template>
  <div class="AdvHomePart6">
    <!-- <img class="rightImg" style="left: 0;top: 33%;" v-lazy="$StaticFileUrl + '/mp/img/brief_bag.png'" alt=""/>
    <img class="rightImg" style="right: 0;top: 52%;" v-lazy="$StaticFileUrl + '/mp/img/blief_bag.png'" alt=""/> -->
    <div class="reply_box">
      <div class="reply_content wow animate__fadeInLeft" data-wow-duration="1s">
        <div class="reply_title">使用场景</div>
        <div class="reply_desc">基于海量权益产品 覆盖100+场景解决方案</div>
        <div class="reply_buttom">
          <div
            v-for="(item, index) in AdvHomePart6_Data"
            :key="index"
            :class="{ activebox: activeKey === index }"
            @mouseenter="activeKey = index"
          >
            <img
              :src="activeKey === index ? item.selectIcon : item.icon"
              :alt="item.name"
              class="img0"
            />
            <div class="headline">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="reply_right">
        <div class="wow animate__fadeInRight" data-wow-duration="1s">
          <div class="reply_head">
            {{ AdvHomePart6_Data[activeKey].name }}
          </div>
          <div class="reply_desc">
            {{ AdvHomePart6_Data[activeKey].text }}
          </div>
          <div class="reply_more">
            <a href="javascript:void(0)">了解更多 ></a>
          </div>
        </div>
        <img
          :src="AdvHomePart6_Data[activeKey].imgUrl"
          class="animate__fadeInRight wow"
          data-wow-duration="1s"
          alt=""
        />
        <div
          class="reply_bg animate__fadeInRight wow"
          data-wow-duration="1s"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import AdvHomePart6_Data from "@/views/ProductServer/AdvHome/Data/AdvHomePart6_Data";
import WOW from "wowjs";

/**
 * @author  XuHongli
 * @date  2022/12/29 11:12
 * @version 1.0
 * @description
 */
export default {
  name: "AdvHomePart6",
  data() {
    return {
      AdvHomePart6_Data,
      activeKey: 0,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.AdvHomePart6 {
  position: relative;
  width: 100%;
  background: #f6f8fa;
  padding: 68px 0 55px;
  height: 782px;
}
.reply_box {
  width: 1250px;
  margin: 0 auto;
  padding: 0 0 0 91px;
  display: flex;
  justify-content: space-between;
  .reply_content {
    margin: 60px 76px 0 0;
    width: 400px;
    .reply_title {
      font-size: 44px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-weight: 400;
      color: #222;
    }
    .reply_desc {
      line-height: 24px;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-weight: 400;
      color: #666;
      margin: 18px 0 60px;
    }
    .reply_buttom {
      margin-top: 86px;
      display: flex;
      flex-flow: wrap;
      width: 400px;
      > .activebox {
        background: #fff;
        box-shadow: 0 3px 29px 1px rgba(99, 99, 99, 0.16);
        cursor: pointer;
        .headline {
          color: #ff6a00;
        }
      }
      > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100px;
        height: 128px;
        .img0 {
          width: 50px;
          height: 45px;
        }
        img {
          margin-bottom: 11px;
        }
        .headline {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
          font-weight: 400;
          color: #222;
        }
      }
    }
  }
}
.reply_right {
  display: flex;
  align-items: center;
  position: relative;
  > div:first-child {
    width: 220px;
    height: 320px;
    background: #fff;
    box-shadow: 0 3px 23px 1px hsla(0, 0%, 41.6%, 0.11);
    margin-top: 50px;
    margin-right: -18px;
    z-index: 99;
    padding: 30px;
    .reply_head {
      font-size: 28px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-weight: 400;
      color: #222;
      margin-bottom: 30px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -8px;
        width: 26px;
        height: 4px;
        background: #ff6a00;
      }
    }
    .reply_desc {
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-weight: 400;
      color: #666;
      line-height: 28px;
      margin-bottom: 15px;
    }
    .reply_more {
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ff6a00;
      cursor: pointer;
      a {
        display: block;
        width: 100%;
        height: 100%;
        color: inherit;
      }
    }
  }
  img {
    width: 339px;
    height: 653px;
    z-index: 2;
  }
  .reply_bg {
    position: relative;
    display: inline-block;
    padding: 15px;
    color: #fff;
    font-size: 24px;
    width: 196px;
    height: 248px;
    margin-left: -182px;
    margin-bottom: -200px;
    &::before {
      background: #ff6a00;
      z-index: 1;
      position: absolute;
      content: "";
      top: 58px;
      left: -8px;
      right: 0;
      bottom: 0;
      transform: skewY(154deg);
    }
  }
}
</style>
