<template>
  <div class="AdvHomePart7">
    <div class="enterprise_bock wow animate__fadeInUp" data-wow-duration=".5s">超500家优质企业的共同选择</div>
    <div class="enterprise_box wow animate__fadeInUpBig" data-wow-duration="1s">
      <div class="enterprise_box_item">
        <ul>
          <li v-for="(item,index) in AdvHomePart7_Data.one" :key="index">
            <img :src="item" alt="">
          </li>
        </ul>
      </div>

      <div class="enterprise_box_item">
        <ul>
          <li v-for="(item,index) in AdvHomePart7_Data.two" :key="index">
            <img :src="item" alt="">
          </li>
        </ul>
      </div>

      <div class="enterprise_box_item">
        <ul>
          <li v-for="(item,index) in AdvHomePart7_Data.three" :key="index">
            <img :src="item" alt="">
          </li>
        </ul>
      </div>

      <div class="enterprise_box_item">
        <ul>
          <li v-for="(item,index) in AdvHomePart7_Data.four" :key="index">
            <img :src="item" alt="">
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import AdvHomePart7_Data from '@/views/ProductServer/AdvHome/Data/AdvHomePart7_Data'
import WOW from 'wowjs'

/**
 * @author  XuHongli
 * @date  2022/12/29 15:14
 * @version 1.0
 * @description
 */
export default {
  name: 'AdvHomePart7',
  data() {
    return {
      AdvHomePart7_Data,
      mounted() {
        new WOW.WOW().init()
      },
    }
  },
}
</script>

<style scoped lang="scss">
.AdvHomePart7{
  margin: 0 auto;
  border-radius: 0 0 0 0;
  opacity: 1;
  overflow: hidden;
  .enterprise_bock{
    text-align: center;
    font-size: 44px;
    font-family: Microsoft YaHei-Regular,Microsoft YaHei,serif;
    font-weight: 400;
    color: #222;
    margin-top: 93px;
    margin-bottom: 50px;
  }
  .enterprise_box{
    width: 100%;
    height: 590px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    .enterprise_box_item{
      position: relative;
      height: 126px;
      display: flex;
      justify-content: space-around;
      margin-top: 10px;
      ul{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        li{
          float: left;
          margin: 0 12px;
          img{
            width: 109px;
            height: 126px;
          }
        }
      }
    }
  }
}
</style>
