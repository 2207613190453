/**
 * @author  XuHongli
 * @date  2022/12/26 18:10
 * @version 1.0
 * @description
 */

const AdvHomePart3_Data = [
  {
    name: '信息流广告',
    subTitle: '依托于原生的页面内容通过图文或视频的方式展现的广告形式',
    text: '体验感佳，信息流广告保证了广告内容与形式的原生性，对用户的干扰小；形式多样，信息流广告展现形式丰富多样，可与创意很好的结合组成不同的玩法',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/video_first.gif'
  },
  {
    name: 'Banner广告',
    subTitle: '应用界面顶部、中部或底部展现的多形态广告样式',
    text: '展现时间长、曝光高、长停留， 能够大大提高广告转化率；同时，banner广告形式较为“委婉”，对用户影响小',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/gif_02.gif'
  },
  {
    name: '激励视频广告',
    subTitle: '通过应用内奖励吸引用户主动选择观看的全屏视频形式广告',
    text: '能在不折损用户体验的同时巧妙提升转化率，提升留存率，增加用户粘性，同时带来更高的收益',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/gif_03.gif'
  },
  {
    name: '开屏广告',
    subTitle: '应用打开画面展示的广告，通常以单图、动态图、视频形式进行展现',
    text: '依托于独特的位置带来的高曝光，开屏广告展示时无其他干扰内容，使得用户视线能够聚焦于广告之上，加深对品牌印象；开屏广告极具变现优势和价值，能够带来可观的变现收益',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/img_sj.gif'
  },
  {
    name: '插屏广告',
    subTitle: '应用打开画面展示的广告，通常以单图、动态图、视频形式进行展现',
    text: '尺寸大、视觉效果震撼，视觉冲击力强，拥有更佳的用户体验，更好的广告效果，超高转化率',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/video_fifth.gif'
  }
]

export default AdvHomePart3_Data
