<template>
  <div class="AdvHomePart2">
    <div class="brand_block wow animate__zoomIn" data-wow-duration="1s">
      <div class="block_maintitle"></div>
    </div>

    <div
      class="tabs-has-line no-line wow animate__fadeInUpBig"
      data-wow-duration="0.5s"
    >
      <a-tabs v-model="activeKey">
        <a-tab-pane
          v-for="item in AdvHomePart2_Data"
          :key="item.name"
          :tab="item.name"
          style="height: 500px"
        >
          <div class="advantage_content" v-if="activeKey === item.name">
            <div class="advantage_img">
              <img
                :src="item.imgUrl"
                :class="['animation-duration-1', 'wow', item.className]"
                data-wow-duration="1s"
              />
            </div>
            <div class="advantage_title wow animation-duration-1">
              <div class="advantage_headline">{{ item.name }}</div>
              <div class="advantage_subtitle">
                <img
                  :src="
                    $StaticFileUrl +
                    '/mp/pages/ProductServer/AdvHome/left_first.png'
                  "
                  alt=""
                />
                {{ item.text1 }}
              </div>
              <div class="advantage_submit advantage_subtitle">
                <img
                  :src="
                    $StaticFileUrl +
                    '/mp/pages/ProductServer/AdvHome/left_first.png'
                  "
                  alt=""
                />
                {{ item.text2 }}
              </div>
              <div class="buttom_push1">
                <a href="javascript:void(0)">立即体验 > </a>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/26 17:28
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
import AdvHomePart2_Data from "@/views/ProductServer/AdvHome/Data/AdvHomePart2_Data";
export default {
  name: "AdvHomePart2",
  data() {
    return {
      activeKey: "实时监控",
      AdvHomePart2_Data,
    };
  },
  created() {},
  mounted() {
    new WOW.WOW().init();
    document
      .querySelectorAll(".AdvHomePart2 .tabs-has-line .ant-tabs-nav>div div")
      .forEach((ele) => {
        ele.addEventListener("mouseenter", this.mouseEnterTabsEvent);
      });
  },
  beforeDestroy() {
    document
      .querySelectorAll(".AdvHomePart2 .tabs-has-line .ant-tabs-nav>div div")
      .forEach((ele) => {
        ele.removeEventListener("mouseenter", this.mouseEnterTabsEvent);
      });
  },
  methods: {
    mouseEnterTabsEvent(e) {
      this.activeKey = e.target.innerText;
    },
  },
};
</script>

<style scoped lang="scss">
.advantage_content {
  display: flex;
  justify-content: center;
  height: 425px;
  .advantage_img {
    width: 600px;
    min-height: 328px;
    height: 400px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 564px;
      height: 461px;
    }
  }

  .advantage_title {
    margin: 25px 0 25px 50px;
    text-align: left;
    position: relative;
    .advantage_headline {
      font-size: 26px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-weight: 400;
      color: #333;
      margin-top: 50px;
    }
    .advantage_subtitle {
      width: 460px;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-weight: 400;
      color: #666;
      line-height: 30px;
      margin-top: 26px;
    }
    .advantage_submit {
      margin-top: 0;
    }
    .buttom_push1 {
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ff6a00;
      margin-top: 30px;
      a {
        color: #ff6a00;
      }
    }
  }
}
</style>
