<template>
  <div class="AdvHome">
    <BannerVideo :video-url="$StaticFileUrl + '/mp/video/advhomeVideo.mp4'">
      <div class="title wow fadeInDown" data-wow-duration="1s">数智化广告平台</div>
      <div class="desc wow fadeInDown" data-wow-duration="1s">依托精准的算法能力、独特的创意玩法打造多元化的互动广告平台<br>助力流量主轻松实现APP、H5小程序流量变现，提升收益</div>
      <div class="flex-row flex-col-center">
        <div class="btn-red wow flipInX" data-wow-duration="1s" style="margin-top: 100px">立即体验</div>
        <div class="btn-red-linear wow flipInX" data-wow-duration="1s" style="margin-top: 100px">广告投放</div>
      </div>
    </BannerVideo>
    <AdvHomePart1></AdvHomePart1>
    <AdvHomePart2></AdvHomePart2>
    <AdvHomePart3></AdvHomePart3>
    <AdvHomePart4></AdvHomePart4>
    <AdvHomePart5></AdvHomePart5>
    <AdvHomePart6></AdvHomePart6>
    <AdvHomePart7></AdvHomePart7>
  </div>
</template>

<script>

import BannerVideo from '@/components/BannerVideo'
import AdvHomePart1 from '@/views/ProductServer/AdvHome/AdvHomePart1'
import AdvHomePart2 from '@/views/ProductServer/AdvHome/AdvHomePart2'
import AdvHomePart3 from '@/views/ProductServer/AdvHome/AdvHomePart3'
import AdvHomePart4 from '@/views/ProductServer/AdvHome/AdvHomePart4'
import AdvHomePart5 from '@/views/ProductServer/AdvHome/AdvHomePart5'
import AdvHomePart6 from '@/views/ProductServer/AdvHome/AdvHomePart6'
import AdvHomePart7 from '@/views/ProductServer/AdvHome/AdvHomePart7'
/**
 * @author  XuHongli
 * @date  2022/12/26 16:21
 * @version 1.0
 * @description
 */
export default {
  name: 'AdvHome',
  components: {
    AdvHomePart7,
    AdvHomePart6, AdvHomePart5, AdvHomePart4, AdvHomePart3, AdvHomePart2, AdvHomePart1, BannerVideo}
}
</script>

<style scoped>

</style>
