<template>
  <div class="AdvHomePart1">
    <img
      class="rightImg"
      style="left: 0; top: 33%"
      v-lazy="$StaticFileUrl + '/mp/img/brief_bag.png'"
      alt=""
    />
    <img
      class="rightImg"
      style="right: 0; top: 33%"
      v-lazy="$StaticFileUrl + '/mp/img/blief_bag.png'"
      alt=""
    />

    <div class="reply_title">一站式广告平台</div>

    <div class="perfect_content">
      <div class="perfect_content_left">
        <div
          class="perfect_content_leftImg"
          v-for="(item, index) in AdvHomePart1_Data"
          :key="index"
        >
          <span>{{ item.title }}</span>
          <div class="perfect_content_leftSpan">{{ item.text }}</div>
        </div>
      </div>
      <div class="perfect_content_right">
        <div>
          <img
            :src="
              $StaticFileUrl +
              '/mp/pages/ProductServer/AdvHome/dzht_aqtx_img_1.png'
            "
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from "wowjs";
import AdvHomePart1_Data from "@/views/ProductServer/AdvHome/Data/AdvHomePart1_Data";

/**
 * @author  XuHongli
 * @date  2022/12/26 16:50
 * @version 1.0
 * @description
 */
export default {
  name: "AdvHomePart1",
  data() {
    return {
      AdvHomePart1_Data,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.AdvHomePart1 {
  position: relative;
  margin-top: 91px;
  .reply_title {
    text-align: center;
    font-size: 44px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
    font-weight: 400;
    color: #222;
    line-height: 40px;
    margin-bottom: 28px;
  }
}
.perfect_content {
  width: 1280px;
  background: #fff;
  position: relative;
  display: flex;
  margin: 42px auto auto;
  .perfect_content_right {
    width: 100%;
    height: 100%;
    div {
      position: relative;
      width: 612px;
      height: 526.71px;
      margin: 95px auto 107px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .perfect_content_left {
    .perfect_content_leftImg {
      width: 390px;
      height: 141px;
      margin-bottom: 60px;
      transition: all 1s;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      &:hover {
        transform: translateY(-15px);
      }
      &:first-child {
        position: absolute;
        top: 90px;
        left: 70px;
        width: 310px;
      }
      &:nth-child(2) {
        position: absolute;
        top: 305px;
        left: -25px;
        width: 360px;
      }
      &:nth-child(3) {
        position: absolute;
        top: 530px;
        left: 70px;
        z-index: 2;
        width: 330px;
      }
      &:nth-child(4) {
        position: absolute;
        top: 90px;
        right: -24px;
        width: 400px;
      }
      &:nth-child(5) {
        position: absolute;
        top: 305px;
        right: -34px;
        width: 360px;
      }
      &:nth-child(6) {
        position: absolute;
        top: 540px;
        right: -24px;
      }
      span {
        font-size: 22px;
        font-weight: 400;
        color: #000;
        line-height: 22px;
      }
      .perfect_content_leftSpan {
        font-size: 18px;
        font-weight: 400;
        color: #666;
        line-height: 27px;
        margin-top: 18px;
      }
    }
  }
}
</style>
