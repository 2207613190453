<template>
  <div class="AdvHomePart4">
    <!-- <img
      class="rightImg"
      style="left: 0; bottom: 5%"
      v-lazy="$StaticFileUrl + '/mp/img/brief_bag.png'"
      alt=""
    />
    <img
      class="rightImg"
      style="right: 0; top: 15%"
      v-lazy="$StaticFileUrl + '/mp/img/blief_bag.png'"
      alt=""
    /> -->
    <div class="scene_block wow animate__zoomIn" data-wow-duration="1s">
      投放策略
    </div>
    <div class="scene_nav_isc">
      <div
        :class="[
          'scene_nav_threebox',
          'wow',
          { animate__fadeInDown: index % 2 === 0 },
          { animate__fadeInUp: index % 2 === 1 },
        ]"
        v-for="(item, index) in AdvHomePart4_Data"
        :key="index"
        data-wow-duration="1s"
      >
        <div class="scene_nav_threebox_top">
          <div class="scene_nav_threebox_img">
            <img :src="item.imgUrl" alt="" />
          </div>
        </div>
        <div class="scene_nav_threebox_bottom">
          <div class="scene_nav_threebox_bottom_title">
            {{ item.title }}
          </div>
          <div class="scene_nav_threebox_bottom_p">
            {{ item.text }}
          </div>
          <div class="scene_nav_threebox_bottom_ty">
            <a href="javascript:void(0)"> 立即体验 ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdvHomePart4_Data from "@/views/ProductServer/AdvHome/Data/AdvHomePart4_Data";
import WOW from "wowjs";
/**
 * @author  XuHongli
 * @date  2022/12/28 18:28
 * @version 1.0
 * @description
 */
export default {
  name: "AdvHomePart4",
  data() {
    return {
      AdvHomePart4_Data,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.AdvHomePart4 {
  position: relative;
  width: 100%;
  padding: 72px 0 54px;
  .scene_block {
    margin-bottom: 55px;
    text-align: center;
    font-size: 44px;
  }
  .scene_nav_isc {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 1280px;
    border-radius: 0 0 0 0;
    margin: 0 auto;
    opacity: 1;
    .scene_nav_threebox {
      flex: 1;
      height: 500px;
      &:nth-child(2) {
        margin: 0 29px;
      }
      .scene_nav_threebox_top {
        height: 221px;
        width: 100%;
        box-sizing: border-box;
        .scene_nav_threebox_img {
          width: 100%;
          height: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
      .scene_nav_threebox_bottom {
        height: 279px;
        width: 100%;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei, serif;
        font-weight: 700;
        box-sizing: border-box;
        padding: 39px 0 0 32px;
        box-shadow: 0 3px 39px 1px rgba(0, 0, 0, 0.08);
        .scene_nav_threebox_bottom_title {
          position: relative;
          height: 35px;
          font-size: 26px;
          color: #222;
          line-height: 32px;
          &::before {
            content: "";
            position: absolute;
            bottom: -12px;
            left: 0;
            display: block;
            width: 45px;
            height: 3px;
            background: #ff6a00;
            border-radius: 148px 148px 148px 148px;
            opacity: 1;
          }
        }
        .scene_nav_threebox_bottom_p {
          margin-top: 38px;
          width: 344px;
          font-size: 18px;
          color: #666;
        }
        .scene_nav_threebox_bottom_ty {
          margin-top: 34px;
          font-size: 18px;
          color: #999;
          line-height: 32px;
          a {
            color: rgb(255, 11, 11);
          }
        }
      }
    }
  }
}
</style>
