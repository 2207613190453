<template>
  <div class="AdvHomePart3">
    <!-- <img class="rightImg" style="left: 0;top: 5%;" v-lazy="$StaticFileUrl + '/mp/img/brief_bag.png'" alt=""/>
    <img class="rightImg" style="right: 0;top: 5%;" v-lazy="$StaticFileUrl + '/mp/img/blief_bag.png'" alt=""/> -->

    <div class="block_maintitle wow animate__zoomIn" data-wow-duration="1s">
      投放形式
    </div>
    <div class="tabList flex-row flex-col-center flex-row-center">
      <div
        v-for="(item, index) in AdvHomePart3_Data"
        :key="index"
        :class="{ 'is-active': activeKey === index }"
        @mouseenter="activeKey = index"
      >
        <img
          class="img_first"
          :src="
            $StaticFileUrl +
            '/mp/pages/ProductServer/AdvHome/' +
            (activeKey === index ? 'rl_001_2.png' : 'rl_02_2.png')
          "
          alt=""
        />
        <div class="mt-20">{{ item.name }}</div>
      </div>
    </div>
    <template v-for="(item, index) in AdvHomePart3_Data">
      <div class="sign_tabs_nav" :key="index" v-show="activeKey === index">
        <div class="sign_tabs_content animation-duration-1 animate__fadeIn">
          <img :src="item.imgUrl" alt="" />
        </div>
        <div
          class="sign_tabs_block animation-duration-1 animate__shortFadeInLeft"
        >
          <div class="block_maintitle">
            {{ item.name }}
          </div>
          <div class="block_maintitle_p">{{ item.subTitle }}</div>
          <div class="item_advertisement_box">
            <div class="item_advertisement_box_first">
              <div class="box_first">场景优势</div>
              <div class="box_span">{{ item.text }}</div>
              <div class="experience">
                <a href="javascript:void(0)"> 立即体验 </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/26 18:18
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
import AdvHomePart3_Data from "@/views/ProductServer/AdvHome/Data/AdvHomePart3_Data";
export default {
  name: "AdvHomePart3",
  data() {
    return {
      AdvHomePart3_Data,
      activeKey: 0,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.AdvHomePart3 {
  background: #f6f8fa;
  position: relative;
  padding-bottom: 70px;
}
.sign_content {
  position: relative;
}
.block_maintitle {
  padding: 50px 0 45px;
  text-align: center;
}
.tabList {
  > div {
    width: 140px;
    height: 134px;
    margin: 0 18px 0 0;
    line-height: 0;
    border-radius: 6px 6px 6px 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.is-active {
      background-color: #fff;
    }
    .img_first {
      width: 39px !important;
      height: 56px !important;
    }
    > div {
      font-size: 18px;
      margin-top: 10px;
    }
  }
}
.sign_tabs_nav {
  height: 100%;
  width: 1280px;
  margin: 0 auto;
  padding: 40px 55px 0;
  display: flex;
  .sign_tabs_content {
    padding-right: 150px;
    padding-left: 100px;
  }
  .sign_tabs_block {
    padding: 45px 0 0;
    .block_maintitle {
      text-align: left;
      position: relative;
      padding: unset;
      color: #222;
      &::after {
        content: "";
        position: absolute;
        width: 38px;
        height: 6px;
        bottom: -8px;
        left: 0;
        background: #ff6a00;
        border-radius: 35px 35px 35px 35px;
      }
    }
    .block_maintitle_p {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-weight: 400;
      color: #222;
      line-height: 26px;
      margin-top: 50px;
    }
    .item_advertisement_box {
      width: 100%;
      height: 294px;
      margin-top: 42px;
      border-radius: 10px 10px 10px 10px;
      .item_advertisement_box_first {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        width: 365px;
        height: 100%;
        vertical-align: top;
        background: #fff;
        padding: 37px 18px 0 27px;
        border-radius: 10px 10px 10px 10px;
        .box_first {
          width: 88px;
          height: 29px;
          font-size: 22px;
          color: #222;
        }
        .box_span {
          height: 100px;
          font-size: 18px;
          margin-top: 15px;
          color: #222;
          line-height: 26px;
        }
        .experience {
          position: absolute;
          bottom: 18px;
          left: 50%;
          transform: translateX(-50%);
          width: 252px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          font-size: 18px;
          color: red;
          &::before {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            display: block;
            width: 235px;
            height: 1px;
            background-color: #999;
            opacity: 0.18;
          }
          a {
            color: red;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
</style>
