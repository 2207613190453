/**
 * @author  XuHongli
 * @date  2022/12/26 16:50
 * @version 1.0
 * @description
 */

const AdvHomePart1_Data = [
  {
    title: '海量流量',
    text: '整合海量媒体资源，开创互动体验模式，提高广告曝光机会及转化，助力营销'
  },
  {
    title: '智能投放',
    text: '通过用户行为、定向分析实现精准投放并通过实时竞价的方式，提高变现收益和运营效率'
  },
  {
    title: '数据分析',
    text: '提供全方位、多维度数据统计报告，分析投放效果，为广告效果及效果转化提供数据依据'
  },
  {
    title: '优质资源',
    text: '多行业、多领域覆盖，提供丰富、优质的广告资源，主流的广告场景，灵活满足展示需求，提高填充率'
  },
  {
    title: '产品创新',
    text: '多样化的广告展现形式，创新的体验玩法，为开发者提供多样化选择'
  },
  {
    title: '便捷对接',
    text: 'SDK便捷接入，减少开发成本'
  }
]

export default AdvHomePart1_Data
