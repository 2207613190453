/**
 * @author  XuHongli
 * @date  2022/12/29 9:35
 * @version 1.0
 * @description
 */
const AdvHomePart5_Data = [
  {
    title: '合约投放',
    text: '细化交易模式专属定制投放广告主可以根据自身商品特点、目标人群特征向广告提出投放需求',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_cxtf_isc_001.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_cxtf_img_001.png'
  },
  {
    title: '竞标投放',
    text: '有效地给用户带来品牌冲击；有利于形成“橱窗效应”，塑造不断攀升的品牌价值和转化效果',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_cxtf_isc_002.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_cxtf_img_002.png'
  },
  {
    title: '渠道投放',
    text: '管理简化，成本低；集中精力，创造核心竞争优势；市场覆盖面广，有利于大量销售，降低风险，迅速提高市场影响力',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_cxtf_isc_003_2.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_cxtf_img_003.png'
  },
  {
    title: '直客投放',
    text: '比较准确地进行活动，大幅度地降低营销成本，提高营销的命中率和成效',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_cxtf_isc_004.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_cxtf_img_004.png'
  }
]

export default AdvHomePart5_Data
