/**
 * @author  XuHongli
 * @date  2022/12/26 17:05
 * @version 1.0
 * @description
 */

const AdvHomePart2_Data = [
  {
    name: '实时监控',
    text1: '数据的好坏准确，直接影响到用户对产品的判断。',
    text2: '产品的数据出现与用户认知产品出现出入时，会让用户重新产生新的认知。',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngg_cpys_img_001.png',
    className: 'animate__fadeInBottomLeft'
  },
  {
    name: '多渠道对比',
    text1: '不同渠道的数据对比，有效挑选出合适的渠道，提高渠道投放的收益。',
    text2: '使统计平台的安全性和数据准确性得到提高。',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngg_cpys_img_002.png',
    className: 'animate__zoomIn'
  },
  {
    name: '安全可靠',
    text1: '数据的安全性及服务的可靠性，是重点投入方向，确保快速处理请求。',
    text2: '尊重与保护你的数据隐私安全，提供稳定可靠的产品服务。',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngg_cpys_img_003.png',
    className: 'animate__fadeInBottomRight'
  },
  {
    name: '集成完善',
    text1: '好的数据管理可以提升数据质量、访问效率，降低数据的维护成本。',
    text2: '理解数据的含义，提升质量，加速对多数据源的复合查询。',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngg_cpys_img_004.png',
    className: 'animate__fadeInUp'
  }
]

export default AdvHomePart2_Data
