/**
 * @author  XuHongli
 * @date  2022/12/29 11:39
 * @version 1.0
 * @description
 */

const AdvHomePart6_Data = [
  {
    name: '汽车租售',
    text: '在平台开展您的产品宣传，提升流量，增加转化',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/x_img_001.png',
    selectIcon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/yw_car.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngg_bj_img_001.png',
  },
  {
    name: '教育培训',
    text: '在平台开展您的招生教育工作，帮助您获取海量客源，展开线上教学',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/yw_three.png',
    selectIcon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/x_img_003.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngg_bj_img_002.png',
  },
  {
    name: '招商加盟',
    text: '针对招商加盟行业，提供广告营销全套解决方案，助您高效招商引资',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/yw_third.png',
    selectIcon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/x_img_002.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngg_bj_img_003.png',
  },
  {
    name: '家居建材',
    text: '在平台推广您的家居建材商品及服务，助您在行业内快速成功',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/yw_last.png',
    selectIcon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/x_img_003.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngg_bj_img_004.png',
  },
  {
    name: '游戏娱乐',
    text: '增加推广速度，减少企业成本',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_ywcj_isc_001.png',
    selectIcon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_ywcj_img_001.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngg_bj_img_005_2.png',
  },
  {
    name: '餐饮连锁',
    text: '提高获客速度，增加流量转化',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_ywcj_isc_002.png',
    selectIcon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_ywcj_img_002.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngg_bj_img_006.png',
  },
  {
    name: '房产行业',
    text: '大数据推流到精准人群，提高企业收益',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_ywcj_isc_003.png',
    selectIcon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zlgg_ywcj_img_003.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/ProductServer/AdvHome/zngg_bj_img_007.png',
  },
]
export default AdvHomePart6_Data
